import React from "react"

import Layout from "../components/layout"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import SEO from "../components/seo"
import { Link } from "gatsby"

const FirstResponders = () => {
  let language = "en"

  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={language}>
        <SEO
          lang="en"
          title="Learn about our Smiles For First Responders program"
          description="Follow the Smiles For First Responder steps to apply"
        />

        <div className="sffr">
          <div className="sffr__hero sffr__section">
            <img
              className="sffr__hero-img--desktop"
              src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/SDOS/DEV/2022-sfr-desktop-hero"
              alt="smiles for soliders banner"
            />
            <img
              className="sffr__hero-img--mobile"
              src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/SDOS/DEV/2022-sfr-mobile-hero"
              alt="smiles for soliders banner"
            />
          </div>

          <div className="sffr__body sffr__section">
            <div className="sffr__container">
              <h1>SUPPORTING THOSE WHO SERVE</h1>
              <p>
                The Oral Surgery & Dental Implant Specialists of San Diego is
                proud to launch our Smiles For First Responders program. This
                program provides one first responder in our community with a
                complimentary treatment that will restore their smile with a
                fully functioning, natural-looking set of teeth.
              </p>
              <p>
                If you are (or were) a first responder living and/or working in
                the San Diego area who has difficulty eating, smiling, or
                speaking because of missing or failing teeth, this could be a
                life-changing opportunity.
              </p>
            </div>
          </div>

          <div className="sffr__section">
            <div className="sffr__container">
              <div className="sffr__brackets">
                <div className="sffr__about-grid">
                  <div>
                    <p className="sffr__subheading">
                      Thank you for your interest in the Smiles For First
                      Responders program. The application period is now closed.
                      Applicants will be notified once the selection process has
                      been completed.
                    </p>
                  </div>
                  {/* <div>
                        <p className="sffr__subheading">Treatment Partners:</p>
                        <ul>
                            <li>Restorative Partner: Dr. Rosa Mathai from <a href='https://www.westportaldentalcare.com/' title='Go to West Portal Dental Care Website' target="_blank">West Portal Dental Care</a></li>
                            <li>Dental Lab: </li>
                            <li>Implant Provider: </li>
                        </ul>
                    </div>   */}
                </div>
              </div>
            </div>
          </div>

          <div className="sffr__section">
            <div className="sffr__container">
              <p>
                Follow this page,{" "}
                <a
                  href="https://www.facebook.com/SanDiegoOralSurgery/"
                  title="Follow us on Facebook"
                  target="_blank"
                >
                  Facebook
                </a>
                , and{" "}
                <a
                  href="https://www.instagram.com/sandiegooralsurgery/"
                  title="Follow us on Instagram"
                  target="_blank"
                >
                  Instagram
                </a>{" "}
                for more program updates!
              </p>

              <p>
                <i>
                  Learn more about{" "}
                  <Link
                    to="/oral-surgery-procedures/dental-implants-san-diego-ca/"
                    title="Learn more about dental implants"
                  >
                    dental implants
                  </Link>
                  {", "}
                  <Link
                    to="/oral-surgery-procedures/dental-implants-san-diego-ca/full-arch-restoration/"
                    title="Learn more about full-arch-restoration"
                  >
                    full-arch restoration
                  </Link>
                  , and all of our{" "}
                  <Link
                    to="https://www.sandiegooralsurgery.com/oral-surgery-procedures/"
                    title="Learn more about our procedures"
                  >
                    procedures
                  </Link>{" "}
                  to see how they can improve your oral health and confidence.
                  Whether you need to replace one tooth or an entire arch of
                  teeth, our team has a solution for you.
                </i>
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default FirstResponders
